import React, { useEffect, useState } from 'react';

import { observer } from 'mobx-react';

import {
  StorageService,
  transformDate,
  useDisplayDateTime,
  useLocationStore,
  useTranslation,
} from 'mycheck-core';

import { HeaderActions } from '@components';

import { Action } from '../../../../components/Action/Action';
import { ExperiencesPicker } from '../../../../components/ExperiencesPicker/ExperiencesPicker';
import { TimePicker } from '../../../../components/TimePicker/TimePicker';

export const LocationHeaderActions: React.FC = observer(() => {
  const [experiencesPickerOpen, setExperiencesPickerOpen] = useState(
    !StorageService.getValue(StorageService.STORAGE_KEYS.EXPERIENCE_PICKER),
  );
  const [timePickerOpen, setTimePickerOpen] = useState(false);
  const [hasAvailableDays, setHastAvailableDays] = useState(false);
  const [timePickerStyle, setTimePickerStyle] = useState({ left: 24 });
  const LocationStore = useLocationStore();
  const displayValue = useDisplayDateTime({
    isHotelDateTimeFormat: true,
    toTimeRange: false,
  });

  const { t } = useTranslation();
  const ExperiencesPickerHandle = () => {
    setExperiencesPickerOpen(!experiencesPickerOpen);
    setTimePickerOpen(false);
  };
  const TimePickerHandle = () => {
    setTimePickerOpen(!timePickerOpen);
    setExperiencesPickerOpen(false);
  };

  useEffect(() => {
    if (
      LocationStore.timeSettings &&
      LocationStore.locationTimezoneName &&
      LocationStore.localHotelDateFormat
    ) {
      const days = transformDate(
        LocationStore.locationTimezoneName,
        LocationStore.localHotelDateFormat,
        LocationStore.timeSettings.available_days,
        false,
        LocationStore.timeSettings.open_hours,
      );
      setHastAvailableDays(days.length > 0);
    }
  }, [
    LocationStore.timeSettings,
    LocationStore.locationTimezoneName,
    LocationStore.localHotelDateFormat,
  ]);

  useEffect(() => {
    const experienceAction = document.getElementById('experience-action');
    setTimePickerStyle({
      left: experienceAction ? experienceAction.offsetWidth + 24 : 24,
    });
  }, [timePickerOpen]);

  return (
    <HeaderActions>
      <Action
        id="experience-action"
        label={t(`experience.${LocationStore.selectedExperienceType}`)}
        height={40}
        width="fit-content"
        onClick={ExperiencesPickerHandle}
      />

      {hasAvailableDays && (
        <Action
          id="time-action"
          label={displayValue}
          height={40}
          width="fit-content"
          leftIconName="time"
          onClick={TimePickerHandle}
        />
      )}

      {experiencesPickerOpen && (
        <ExperiencesPicker onClose={ExperiencesPickerHandle} />
      )}
      {timePickerOpen && (
        <TimePicker onClose={TimePickerHandle} style={timePickerStyle} />
      )}
    </HeaderActions>
  );
});

LocationHeaderActions.displayName = 'LocationHeaderActions';
