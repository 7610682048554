import React, { type CSSProperties } from 'react';

import classNames from 'classnames';
import { css } from 'glamor';

import styles from './DropDown.module.scss';

type DropDownLabelType = {
  name: string | JSX.Element;
  style?: CSSProperties;
  onClick?: () => void;
  className?: string;
};

export const DropDownLabel: React.FC<DropDownLabelType> = ({
  name,
  style,
  onClick,
  className,
}) => {
  const styleClassname = css(style);

  return (
    <div
      className={classNames(styles.label, className, {
        [styleClassname.toString()]: !!style,
      })}
      onClick={onClick}
    >
      {name}
    </div>
  );
};
