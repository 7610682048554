import React, { useEffect, useMemo, useState, type CSSProperties } from 'react';

import classNames from 'classnames';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import moment from 'moment-timezone';

import {
  capitalizeFirstLetter,
  transformDate,
  transformTime,
  transformTimeArrayToDateTimeArray,
  useCheckoutStore,
  useLocationStore,
  useTranslation,
} from 'mycheck-core';

import { CustomIcon, Overlay } from '@components';

import styles from '../Picker/Picker.module.scss';
import { DaysSelectValueItem } from 'types/GenericTypes';

interface TimePickerProps {
  onClose: () => void;
  style?: CSSProperties;
  locationHours?: boolean;
  hideClose?: boolean;
}

export const TimePicker: React.FC<TimePickerProps> = observer(
  ({ onClose, style, locationHours, hideClose }) => {
    const LocationStore = useLocationStore();
    const CheckoutStore = useCheckoutStore();
    const { i18n, t } = useTranslation();
    const date = moment(
      locationHours
        ? CheckoutStore.checkoutTime
        : LocationStore.selectedDateTemp,
    )
      .tz(LocationStore.locationTimezoneName)
      .startOf('day');
    const [step, setStep] = useState<'day' | 'time'>('day');
    const [values, setValues] = useState({
      date: date.utc().format(),
      time: locationHours
        ? moment.utc(CheckoutStore.checkoutTime).format()
        : LocationStore.selectedDateTemp,
    });

    const [locationTimesList, setLocationTimesList] = useState([]);
    const restaurants = LocationStore.restaurantList.filter((restaurant) =>
      restaurant.experiences.find(
        (experience) =>
          experience.type === LocationStore.selectedExperienceType,
      ),
    );

    const asapRestaurants = restaurants.filter((restaurant) =>
      restaurant.experiences.find(
        (experience) =>
          experience.settings.is_asap_only === true &&
          experience.type === LocationStore.selectedExperienceType,
      ),
    );

    const isAsap = restaurants.length === asapRestaurants.length;

    const { dates, times } = useMemo(() => {
      if (isAsap) {
        return {
          dates: [
            {
              valueDate: t('timeSelection.today'),
              label: t('timeSelection.today'),
            },
          ],
          times: [
            {
              value: t('general.now'),
              label: t('general.now'),
            },
          ],
        };
      }

      if (LocationStore.timeSettings) {
        const newDates = transformDate(
          LocationStore.locationTimezoneName,
          LocationStore.localHotelDateFormat,
          locationHours
            ? LocationStore.selectedExperience?.settings?.available_days
            : LocationStore.timeSettings.available_days,
          false,
          LocationStore.timeSettings.open_hours,
        );

        const newTimes: DaysSelectValueItem = newDates.reduce(
          (result, dateValue) => {
            result[dateValue.value] = transformTime(
              LocationStore.timeSettings,
              dateValue.value,
              LocationStore.locationTimezoneName,
              LocationStore.localHotelTimeFormat,
              false,
            );
            return result;
          },
          {},
        );

        let setNewDates = newDates
          .filter(
            (obj) =>
              !!newTimes[
                moment(obj.value)
                  .tz(LocationStore.locationTimezoneName)
                  .utc()
                  .format()
              ]?.length,
          )
          .map((e) => {
            const value = moment(e.value).tz(
              LocationStore.locationTimezoneName,
            );
            return {
              ...e,
              value: value.date(),
              valueDate: value.clone().utc().format(),
              dayName: value.format('ddd').toLowerCase(),
            };
          });

        if (LocationStore.selectedExperience) {
          const locationHoursAvailable = Object.keys(
            LocationStore.selectedExperience.settings.open_hours,
          );

          setNewDates = setNewDates.filter((e) => {
            if (locationHoursAvailable.includes(e.dayName)) {
              if (moment().isSame(e.valueDate, 'day')) {
                const openHours =
                  LocationStore.selectedExperience.settings.open_hours[
                    e.dayName
                  ];
                const maxToMoment = moment.max(
                  openHours.map((object) => {
                    return moment()
                      .tz(LocationStore.locationTimezoneName)
                      .startOf('day')
                      .hour(object.to.split(':')[0])
                      .minute(object.to.split(':')[0]);
                  }),
                );

                if (
                  moment()
                    .tz(LocationStore.locationTimezoneName)
                    .isAfter(maxToMoment)
                ) {
                  return false;
                }
              }
              return true;
            }
          });
        }

        return {
          dates: setNewDates,
          times:
            newTimes[values.date] || newTimes[setNewDates[0].valueDate] || [],
        };
      }
    }, [i18n.language, values.date]);

    useEffect(() => {
      document.body.style.overflow = 'hidden';

      return () => {
        document.body.removeAttribute('style');
      };
    }, []);

    useEffect(() => {
      if (step) {
        const element = document.getElementById(
          step === 'day' ? values.date : values.time,
        );
        if (element) {
          element.scrollIntoView({ block: 'center' });
        }
      }
    }, [step, values.time, values.date]);

    const setLocationTimes = async () => {
      const locationHoursAvailable = Object.keys(
        LocationStore.selectedExperience.settings.open_hours,
      );

      const newDates = dates.filter((e) =>
        locationHoursAvailable.includes(
          (e as unknown as { dayName: string }).dayName,
        ),
      );

      const response = await LocationStore.confirmExperienceSlot(
        times[0].value,
        undefined,
        false,
      );

      const locationTimeValues = transformTimeArrayToDateTimeArray(
        (response?.time_slots || LocationStore.timeSlots)[
          moment
            .utc(times[0].value)
            .tz(LocationStore.locationTimezoneName)
            .format('ddd')
            .toLowerCase()
        ] || [],
        newDates.find((e) => e.valueDate === values.date)?.valueDate,
        LocationStore.locationTimezoneName,
        locationHours
          ? LocationStore.localHotelTimeFormat
          : LocationStore.localTimeFormat,
        LocationStore.selectedExperience.settings.frame_time,
      );

      setLocationTimesList(locationTimeValues);
    };

    const onSubmit = async (time: string) => {
      const _time = time || values.time;
      if (locationHours) {
        CheckoutStore.setSelectedCheckoutTime(_time);
      }

      LocationStore.setSelectedDateTemp(_time);
      LocationStore.setSelectedTimeTemp(_time);

      const lastChoice = LocationStore.selectedDate;
      LocationStore.setSelectedDate(LocationStore.selectedDateTemp);
      LocationStore.setSelectedTime(LocationStore.selectedDateTemp);

      if (lastChoice !== LocationStore.selectedDateTemp) {
        LocationStore.startFetching();
        await LocationStore.fetchRestaurants(LocationStore.selectedHotel);
      }

      onClose();
    };

    const handleChange = (name: string, value: string) => {
      if (value) {
        if (name === 'day') {
          if (values.date === value) {
            setStep('time');
          }

          setValues({
            date: value,
            time: values.time,
          });
        }

        if (times.length && name === 'time') {
          if (
            (locationHours ? locationTimesList : times).filter((time) => {
              return time.value === value;
            }).length
          ) {
            if (values.time === value) {
              if (isAsap) {
                onClose();
              } else {
                onSubmit(value);
              }
            }

            setValues({
              date: values.date,
              time: value,
            });
          }
        }
      }
    };

    useEffect(() => {
      if (!isAsap) {
        let _date = values.date;
        let _time = values.time;

        if (locationHours && locationTimesList.length) {
          _time =
            locationTimesList.filter((locationTime) => {
              return locationTime.value === values.time;
            })[0]?.value || locationTimesList[0].value;
        } else if (!locationHours) {
          const selectedDay = dates.filter((d) => {
            return d.valueDate === values.date;
          });

          if (selectedDay.length === 0) {
            _date = dates[0].valueDate;
            _time = times[0].value;
          }

          _time =
            times.filter((time) => {
              return time.value === _time;
            })[0]?.value || times[0].value;
        }
        setValues({
          date: _date,
          time: _time,
        });
      }
    }, [times, locationTimesList]);

    useEffect(() => {
      (async () => {
        if (locationHours) {
          await setLocationTimes();
        }
      })();
    }, [locationHours, values.date]);

    return (
      <>
        <Overlay
          withoutModal
          overlayStyle={{ top: 128, backdropFilter: 'blur(2px)' }}
          onClickOutside={() => {
            if (!hideClose) {
              onClose();
            }
          }}
          testId="time-picker"
        >
          <div className={styles.picker} style={style}>
            <div className={styles.pickerHeader}>
              {!hideClose && (
                <div onClick={onClose}>
                  <CustomIcon
                    name={'x'}
                    className={styles.pickerHeaderCloseIcon}
                  ></CustomIcon>
                </div>
              )}
              <div className={styles.pickerHeaderTitle}>
                {' '}
                {step === 'day'
                  ? t('timeSelection.selectDay')
                  : capitalizeFirstLetter(
                      t('timeSelection.selectTimeTitle').toLowerCase(),
                    )}
              </div>
            </div>

            {step === 'day' && (
              <div
                className={classnames(
                  styles.pickerList,
                  dates.length > 4 ? styles.pickerListLinear : '',
                )}
                style={dates.length > 4 ? { paddingBottom: 100 } : {}}
              >
                {dates.map((dateValue) => (
                  <div
                    key={dateValue.valueDate}
                    id={dateValue.valueDate}
                    className={classNames(
                      styles.pickerListItem,
                      values.date === dateValue.valueDate || isAsap
                        ? styles.pickerListItemActive
                        : '',
                    )}
                    onClick={() => {
                      handleChange('day', dateValue.valueDate);
                    }}
                  >
                    {dateValue.label}
                  </div>
                ))}
              </div>
            )}

            {step === 'time' && (
              <div
                className={classnames(
                  styles.pickerList,
                  times.length > 15 ? styles.pickerListLinear : '',
                )}
                style={times.length > 15 ? { paddingBottom: 100 } : {}}
              >
                {(locationHours ? locationTimesList : times).map((time) => (
                  <div
                    key={time.value}
                    id={time.value}
                    className={classNames(
                      styles.pickerListFlexItem,
                      values.time === time.value || isAsap
                        ? styles.pickerListFlexItemActive
                        : '',
                    )}
                    onClick={() => {
                      handleChange('time', time.value);
                    }}
                  >
                    {time.label}
                  </div>
                ))}
              </div>
            )}

            <div className={styles.pickerFooter}>
              {step === 'day' && (
                <div
                  className={styles.pickerFooterRight}
                  onClick={() => setStep('time')}
                >
                  {t('general.next')}
                </div>
              )}
              {step === 'time' && (
                <div
                  className={styles.pickerFooterRight}
                  onClick={() => {
                    if (isAsap) {
                      onClose();
                    } else {
                      onSubmit(null);
                    }
                  }}
                >
                  {t('general.save').toLowerCase()}
                </div>
              )}
              {step === 'time' && (
                <div
                  className={styles.pickerFooterLeft}
                  onClick={() => setStep('day')}
                >
                  {t('general.back')}
                </div>
              )}
            </div>
          </div>
        </Overlay>
      </>
    );
  },
);

TimePicker.displayName = 'TimePicker';
