import React, { useEffect, useMemo } from 'react';

import { Formik } from 'formik';
import get from 'lodash/get';
import moment from 'moment-timezone';
import { useLocation } from 'react-router-dom';
import * as yup from 'yup';

import {
  MyCheckApp,
  useCheckoutStore,
  useLocationStore,
  useTranslation,
  useWindowSize,
} from 'mycheck-core';

import { Icon, Overlay } from '@components';

import styles from './TimeSelectionModal.module.scss';
import { TimeSelectionModalForm } from './TimeSelectionModalForm';

interface Props {
  handleModalOpen: () => void;
  locationHours?: boolean;
  hotelDateTimeFormat: boolean;
  hideClose?: boolean;
}

export const TimeSelectionModal: React.FC<Props> = ({
  handleModalOpen,
  locationHours,
  hotelDateTimeFormat,
  hideClose = false,
}) => {
  const LocationStore = useLocationStore();
  const CheckoutStore = useCheckoutStore();
  const { t } = useTranslation();
  const { isLg } = useWindowSize();
  const location = useLocation();
  const restaurants = LocationStore.restaurantList.filter((restaurant) =>
    restaurant.experiences.find(
      (experience) => experience.type === LocationStore.selectedExperienceType,
    ),
  );

  const asapRestaurants = restaurants.filter((restaurant) =>
    restaurant.experiences.find(
      (experience) =>
        experience.settings.is_asap_only === true &&
        experience.type === LocationStore.selectedExperienceType,
    ),
  );

  const isAsap = restaurants.length === asapRestaurants.length;

  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();

    const styleComponent = () => {
      const style = get(_config, 'restaurants.timeSelection', {});
      return {
        closeIcon: style.icons.x,
        title: style.Title,
        value: style.Value,
      };
    };

    return {
      styleComponent: styleComponent(),
    };
  }, [location.search]);

  const onSubmit = async (pickerValue) => {
    const { time, date } = pickerValue;
    const dateVal = new Date(date);
    dateVal.setHours(new Date(time).getHours());
    dateVal.setMinutes(new Date(time).getMinutes());

    if (locationHours) {
      CheckoutStore.setSelectedCheckoutTime(dateVal as unknown as string);
    }

    LocationStore.setSelectedDateTemp(dateVal as unknown as string);
    LocationStore.setSelectedTimeTemp(dateVal as unknown as string);

    const lastChoice = LocationStore.selectedDate;
    LocationStore.setSelectedDate(LocationStore.selectedDateTemp);
    LocationStore.setSelectedTime(LocationStore.selectedDateTemp);
    if (lastChoice !== LocationStore.selectedDateTemp) {
      LocationStore.startFetching();
      await LocationStore.fetchRestaurants(LocationStore.selectedHotel);
    }

    handleModalOpen();
  };

  const getValidationSchema = () =>
    yup.object().shape({
      date: yup.string().required(),
      time: yup.string().required(),
    });

  const overlayStyle = isLg
    ? { marginTop: 150, width: 320, left: 'unset', zIndex: 2 }
    : {};

  const date = moment(
    locationHours ? CheckoutStore.checkoutTime : LocationStore.selectedDateTemp,
  )
    .tz(LocationStore.locationTimezoneName)
    .startOf('day');

  const initValues = {
    date: date.utc().format(),
    time: locationHours
      ? moment.utc(CheckoutStore.checkoutTime).format()
      : LocationStore.selectedDateTemp,
    isAsap: false,
  };

  const onClose = () => {
    document.body.style.removeProperty('overscroll-behavior-y');
    document.body.style.removeProperty('touch-action');
    document.body.removeAttribute('style');
    document.documentElement.removeAttribute('style');
    document.getElementById('root').removeAttribute('style');

    if (!locationHours) {
      document.getElementById('locationContent').style.overflow = 'auto';
    }
    handleModalOpen();
  };

  const onClickOutside = () => {
    if (!hideClose) {
      setTimeout(() => {
        onClose();
      }, 500);
    }
  };

  useEffect(() => {
    document.body.style['overscroll-behavior-y'] = 'none';
    document.body.style['touch-action'] = 'none';

    document.body.style.height = '100%';
    document.body.style.overflow = 'hidden';

    document.documentElement.style.height = '100%';
    document.documentElement.style.overflow = 'hidden';

    document.getElementById('root').style.height = '100%';
    document.getElementById('root').style.overflow = 'hidden';

    if (!locationHours) {
      document.getElementById('locationContent').style.overflow = 'hidden';
    }

    return () => {
      document.body.style.removeProperty('overscroll-behavior-y');
      document.body.style.removeProperty('touch-action');
      document.body.removeAttribute('style');
      document.documentElement.removeAttribute('style');
      document.getElementById('root').removeAttribute('style');
      const locationContentElement = document.getElementById('locationContent');

      if (!locationHours && locationContentElement) {
        locationContentElement.style.overflow = 'auto';
      }
    };
  }, []);

  return (
    <Overlay
      width={'100%'}
      className={styles.timeModalContainer}
      overlayStyle={overlayStyle}
      onClickOutside={onClickOutside}
    >
      <div
        className={styles.timeModalContainerTitle}
        style={config.styleComponent.title}
      >
        {isLg
          ? t('timeSelection.selectTimeTitle')
          : t('timeSelection.selectDayAndTimeTitle')}
      </div>
      {!hideClose && (
        <div className={styles.timeModalContainerExit} onClick={onClose}>
          <Icon name="x" style={config.styleComponent.closeIcon} />
        </div>
      )}
      <Formik
        initialValues={initValues}
        validationSchema={getValidationSchema}
        onSubmit={isAsap ? onClose : onSubmit}
      >
        <TimeSelectionModalForm
          locationHours={locationHours}
          hotelDateTimeFormat={hotelDateTimeFormat}
          isAsap={isAsap}
        />
      </Formik>
    </Overlay>
  );
};
