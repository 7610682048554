import React, { useEffect, useState } from 'react';

import { Form, useFormikContext } from 'formik';
import moment from 'moment-timezone';

import {
  transformDate,
  transformTime,
  transformTimeArrayToDateTimeArray,
  useDateTimeTrackerHandler,
  useLocationStore,
  useTranslation,
  useWindowSize,
} from 'mycheck-core';

import { Text, PrimaryButton, MobilePickerScroll } from '@components';

import styles from './TimeSelectionModal.module.scss';
import { DaysSelectValueItem } from 'types/GenericTypes';

type Props = {
  locationHours?: boolean;
  hotelDateTimeFormat?: boolean;
  isAsap?: boolean;
};

export const TimeSelectionModalForm: React.FC<Props> = ({
  locationHours,
  hotelDateTimeFormat,
  isAsap = false,
}) => {
  const formik = useFormikContext<{ date: string; time: string }>();
  const { i18n, t } = useTranslation();
  const { isLg } = useWindowSize();

  const [valueGroups, setValueGroups] = useState({
    day: t('timeSelection.today'),
    time: t('general.now'),
  });

  const [optionGroups, setOptionGroups] = useState({
    day: [t('timeSelection.today')],
    time: [t('general.now')],
  });

  const [dates, setDates] = useState([]);
  const [times, setTimes] = useState([]);
  const LocationStore = useLocationStore();
  useDateTimeTrackerHandler(true);

  const handleChange = (name: string, value: string) => {
    if (value) {
      setValueGroups({
        ...valueGroups,
        [name]: value,
      });

      if (name === 'day') {
        formik.setFieldValue(
          'date',
          dates.filter((d) => {
            return d.label === value;
          })[0]?.valueDate || dates[0].valueDate,
        );
      }

      if (times.length && name === 'time') {
        if (times.filter((time) => time.label === value).length) {
          const _time = times.filter((time) => {
            return time.label === value;
          })[0].value;

          formik.setFieldValue('time', _time);
        }
      }
    }
  };

  const setDateAndTimes = async () => {
    const newDates = transformDate(
      LocationStore.locationTimezoneName,
      hotelDateTimeFormat
        ? LocationStore.localHotelDateFormat
        : LocationStore.localDateFormat,
      locationHours
        ? LocationStore.selectedExperience?.settings?.available_days
        : LocationStore.timeSettings.available_days,
      isLg,
      LocationStore.timeSettings.open_hours,
    );

    const newTimes: DaysSelectValueItem = newDates.reduce((result, date) => {
      result[date.value] = transformTime(
        LocationStore.timeSettings,
        date.value,
        LocationStore.locationTimezoneName,
        hotelDateTimeFormat
          ? LocationStore.localHotelTimeFormat
          : LocationStore.localTimeFormat,
        isLg,
      );

      return result;
    }, {});

    const setNewDates = newDates
      .filter(
        (obj) =>
          !!newTimes[
            moment(obj.value)
              .tz(LocationStore.locationTimezoneName)
              .utc()
              .format()
          ]?.length,
      )
      .map((e) => {
        const value = moment(e.value).tz(LocationStore.locationTimezoneName);
        return {
          ...e,
          value: value.date(),
          valueDate: value.clone().utc().format(),
          dayName: value.format('ddd').toLowerCase(),
        };
      });

    if (locationHours) {
      const locationHoursAvailable = Object.keys(
        LocationStore.selectedExperience.settings.open_hours,
      );

      const filteredNewDates = setNewDates.filter((e) => {
        if (locationHoursAvailable.includes(e.dayName)) {
          if (moment().isSame(e.valueDate, 'day')) {
            const openHours =
              LocationStore.selectedExperience.settings.open_hours[e.dayName];
            const maxToMoment = moment.max(
              openHours.map((object) => {
                return moment()
                  .tz(LocationStore.locationTimezoneName)
                  .startOf('day')
                  .hour(object.to.split(':')[0])
                  .minute(object.to.split(':')[0]);
              }),
            );

            if (
              moment()
                .tz(LocationStore.locationTimezoneName)
                .isAfter(maxToMoment)
            ) {
              return false;
            }
          }
          return true;
        }
      });
      setDates(filteredNewDates);

      const response = await LocationStore.confirmExperienceSlot(
        newTimes[formik.values.date][0].value,
        undefined,
        false,
      );

      const locationTimeValues = transformTimeArrayToDateTimeArray(
        (response?.time_slots || LocationStore.timeSlots)[
          moment
            .utc(newTimes[formik.values.date][0].value)
            .tz(LocationStore.locationTimezoneName)
            .format('ddd')
            .toLowerCase()
        ] || [],
        newDates.find((e) => e.valueDate === formik.values.date)?.valueDate,
        LocationStore.locationTimezoneName,
        hotelDateTimeFormat
          ? LocationStore.localHotelTimeFormat
          : LocationStore.localTimeFormat,
        LocationStore.selectedExperience.settings.frame_time,
      );

      setTimes(locationTimeValues);

      const _time =
        locationTimeValues.filter((time) => {
          return time.value === formik.values.time;
        })[0] || locationTimeValues[0];

      setValueGroups({
        day: setNewDates.filter((d) => {
          return d.valueDate === formik.values.date;
        })[0].label,
        time: _time?.label || '',
      });

      setOptionGroups({
        day: newDates.map((date) => date.label),
        time: locationTimeValues.map((time) => time.label || []) as string[],
      });

      if (!locationTimeValues.some((e) => e.value === formik.values.time)) {
        formik.setFieldValue('time', locationTimeValues[0]?.value);
      }
    } else {
      setDates(setNewDates);
      setTimes(
        newTimes[formik.values.date] ||
          newTimes[setNewDates[0].valueDate] ||
          [],
      );

      setOptionGroups({
        day: setNewDates.map((date) => date.label),
        time: (
          newTimes[formik.values.date] ||
          newTimes[setNewDates[0].valueDate] ||
          []
        ).map((time) => time.label || []),
      });

      const selectedDay = setNewDates.filter((d) => {
        return d.valueDate === formik.values.date;
      });

      let _timeLabel = newTimes[formik.values.date]?.filter(
        (time) => time.value === formik.values.time,
      );
      if (!_timeLabel || !_timeLabel.length)
        _timeLabel =
          newTimes[formik.values.date] ||
          newTimes[
            selectedDay.length > 0
              ? selectedDay[0].value
              : setNewDates[0].valueDate
          ];

      setValueGroups({
        day:
          selectedDay.length > 0 ? selectedDay[0].label : setNewDates[0].label,
        time: _timeLabel[0].label,
      });

      if (
        newTimes[formik.values.date] &&
        newTimes[formik.values.date].length &&
        !newTimes[formik.values.date]?.find(
          (e) => e.value === formik.values.time,
        )
      ) {
        formik.setFieldValue('time', newTimes[formik.values.date][0].value);
      }
    }
  };

  useEffect(() => {
    (async () => {
      if (!isAsap) {
        await setDateAndTimes();
      }
    })();
  }, [formik.values.date, i18n.language, LocationStore.timeSlots]);

  return (
    <Form className={styles.timeModalContainerForm}>
      <MobilePickerScroll
        optionGroups={optionGroups}
        valueGroups={valueGroups}
        onChange={handleChange}
        height={isLg ? 150 : 250}
        itemHeight={isLg ? 36 : 40}
      />
      <PrimaryButton
        disabled={times.length === 0 && dates.length === 0 && !isAsap}
        className={styles.primaryButton}
        testId="time-selection"
      >
        <Text value="timeSelection.setTimeButton" />
      </PrimaryButton>
    </Form>
  );
};
